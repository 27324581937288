import React, { useEffect } from 'react';
import Main from 'layouts/main';
import Hero from './hero';
import Team from './team';
import History from './history';
import Investors from './investors';
import Features from './features';
import Values from './values';
import * as constants from 'utils/constants';
import { generateMetaTags } from '../pageHelmet';

const About = (): JSX.Element => {
  useEffect(() => {
    window.onload = () => {
      if (location.hash) {
        window.location.href = location.hash;
      }
    };
  }, []);

  return (
    <Main>
      {generateMetaTags(
        constants.PAGE_CONTENT.ABOUT_US.TITLE,
        constants.PAGE_CONTENT.ABOUT_US.DESCRIPTION,
        constants.PAGE_CONTENT.ABOUT_US.OG_IMAGE,
        window.location.href,
        constants.PAGE_CONTENT.ABOUT_US.KEYWORDS,
      )}
      <Hero />
      <Features />
      <Team />
      <Investors />
      <Values />
      <History />
    </Main>
  );
};

export default About;
