import React from 'react';
import { GridContainer, Container, Image } from 'components';
import { useTheme, Typography, Box } from '@mui/material';
import * as constants from 'utils/constants';

const Hero = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <div style={{ position: 'relative' }}>
      {' '}
      {/* Positioned container */}
      <GridContainer pt={20} pb={0}>
        <Container display="flex" flexDirection="column" gap={2}>
          <Typography
            color="primary.900"
            sx={{ ...typography.sm, ...typography.regular }}
          >
            About Indemn
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', xl: '1fr 1fr' }}
            gap={6.5}
          >
            <Typography
              color="primary.900"
              sx={{ ...typography['2xl'], ...typography.semibold }}
            >
              We’re on a mission to create insurance experiences that people ❤️
              to use.
            </Typography>
            <Typography
              color="primary.900"
              sx={{ ...typography.base, ...typography.regular }}
            >
              We’ve built the first fully conversation native insurance platform
              to make the whole process of learning about and purchasing
              coverage a breeze.
              <br />
              <br />
              We empower you with AI-powered chatbots that can answer questions,
              generate customizable quotes, and execute service requests
              instantly.
            </Typography>
          </Box>
        </Container>
      </GridContainer>
      {/* Hero Background */}
      <div>
        <Image
          src={constants.CLOUDINARY_IMAGES.ABOUT_HERO_IMAGE}
          alt="about-hero-image"
          sx={{ my: 8 }}
          imageProps={{
            style: {
              opacity: 0.64,
              objectFit: 'cover',
              mixBlendMode: 'multiply',
            },
          }}
        />
      </div>
    </div>
  );
};

export default Hero;
