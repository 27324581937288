import React from 'react';
import { GridContainer, Container } from 'components';
import Feature from 'views/components/feature';
import { Typography, useTheme, Box } from '@mui/material';

const values = [
  { title: 'Innovation', content: 'Our aim is not just to offer insurance, but to redefine how customers interact with and experience it.' },
  { title: 'Integrity', content: 'We believe in doing the right thing for our customers, partners, employees and investors.' },
  { title: 'Seamless user experience', content: 'We\'ve harnessed modern tools to provide a dynamic, confident, and seamless user experience, that looks to save you time and energy.' }
];

const Values = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display="flex" flexDirection="column" gap={3}>
      <Container>
        <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
          Values
        </Typography>
      </Container>
      <Box display="grid" gridTemplateColumns={{ xs: '1f', xl: '1fr 1fr 1fr' }} gap={{ xs: 1.5, xl: 0.5 }}>
        {values.map(feature => <Feature key={feature.title} {...feature} />)}
      </Box>
    </GridContainer>
  );
};

export default Values;
