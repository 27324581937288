import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import {
  SectionFeatureSmall,
  SectionFeatureMedium,
  SectionFeatureLarge,
  SectionHero1,
  BotHostContainer,
} from 'views/components';
import ChatLoader from 'context/chatLoader';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const mediumFeatures = [
  {
    title: 'Integrated payments with provider of your choice',
    content:
      'Provide a seamless experience at checkout without navigating away to improve bind rate.',
    media: 'md-payments_w3tvti',
  },
  {
    title: 'Quotes and payments even post-handoff',
    content:
      'Leveraging our live human-handoff, your CSRs can continue to fetch quotes, secure payment, while your users get a singular experience.',
    media: 'md-csr-quotes-payments_znxlr6',
  },
];

const smallFeatures = [
  {
    media: 'sm-25-conversion_m52edw',
    title: 'Record-breaking 25% conversion',
    content:
      'We’ve proven that the conversational method for selling insurance obliterates conventional webform conversion',
  },
  {
    media: 'sm-71-users-to-quote_qsiuwk',
    title: 'Engage to Quote',
    content:
      'Leverage our conversational AI technology to convert more policies when agents are interacting with the system',
  },
  {
    media: 'sm-60-cross-sell_liuat8',
    title: '60% conversion on cross-sell and buy-up',
    content:
      'Displaying multiple quote options led to 60% of customers purchasing a more comprehensive package.',
  },
];

const OnlineQuotesPayments = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_QUOTE.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_QUOTE.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_QUOTE.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_QUOTE.KEYWORDS,
        )}
        <SectionHero1
          title="Digital distribution has entered a new age "
          content="Integrate an AI agent directly into your broker portal, giving appointed agents instant access to key information and support. Reduce call and email traffic by over 30% and increase agent engagement with your products and services."
          media="acquisition-hero-img"
          message="I’m interested in a rich quoting, underwriting, and payment experience"
        />
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />
        <SectionFeatureSmall features={smallFeatures} />

        <SectionFeatureLarge
          title="Conversational data collection for quotes and underwriting"
          content="Deliver a singular experience improving the accuracy and efficiency of data collection and product representation, while letting your users interject when they have questions."
          media="lg-lead-capture_x6hd6g"
        />

        <SectionFeatureMedium features={mediumFeatures} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default OnlineQuotesPayments;
