import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import {
  SectionFeatureSmall,
  SectionFeatureMedium,
  SectionFeatureLarge,
  SectionHero1,
  BotHostContainer,
} from 'views/components';
import ChatLoader from 'context/chatLoader';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const mediumFeatures = [
  {
    title: 'Omni-channel setup to deliver instant responses',
    content:
      'Deliver a game-changing experience with AI that provides a seamless conversational interface across all channels.',
    media: 'md-omnichannel_rbcbfa',
  },
  {
    title: 'Deploy contextually across distribution channels',
    content:
      'Our AI agents adapt to your workflow, and recommend contextually appropriate responses based on the conversation.',
    media: 'md-distribution_iper0t',
  },
];

const smallFeatures = [
  {
    media: 'sm-3x-form-completion_mahxj9',
    title: '3x form completion rate',
    content:
      'Users are 3x more likely to complete the information intake process when using a dynamic chat versus a traditional web form',
  },
  {
    media: 'sm-10x-online-purchase_oycr20',
    title: 'Online Purchase Behavior',
    content:
      'Users who visit your site are 10x more likely to purchase when interacting with a dynamic chat ',
  },
  {
    media: 'sm-28-to-lead_ntdhhr',
    title: 'Interactions converted to Lead',
    content:
      'Users who interact with the live chat are converted to leads at 28% compared to single digit conversion rates with web forms',
  },
];

const LeadCapture = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_LEAD.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_LEAD.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_LEAD.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_LEAD.KEYWORDS,
        )}
        <SectionHero1
          title="Say goodbye to outdated web forms"
          content="Dynamic chat powered by AI  boosts lead capture by 50% with higher quality leads for your agents to close. Give your prospects real time access to policy information across multiple carriers."
          message="I’m interested in deploying Indemn’s high-engagement lead capture for my sales and service needs"
        />

        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        <SectionFeatureMedium features={mediumFeatures} />

        <SectionFeatureLarge
          title="Conversational data collection for quotes and underwriting"
          content="Deliver a singular experience improving the accuracy and efficiency of data collection and product representation, while letting your users interject when they have questions."
          media="lg-lead-capture_x6hd6g"
        />

        <SectionFeatureSmall features={smallFeatures} />

        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default LeadCapture;
