import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { useTheme, Typography, Box } from '@mui/material';
import { FiMessageCircle } from 'react-icons/fi'; // Importing react-icons
import {
  GridContainer,
  Container,
  MarkDown,
  Image,
  CloudImage,
  CloudVideo,
} from 'components';
import { AskIndmenButton, Faq, BotHostContainer } from 'views/components';
import { isMobile } from 'utils/helper-generic';
import ChatLoader from 'context/chatLoader';
import * as constants from 'utils/constants';
import { generateMetaTags } from 'views/pageHelmet';

import {
  SectionFeatureLarge,
  SectionFeatureSmall,
  SectionFeatureMedium,
} from 'views/components';

// const features = [
//   { id: 'home-acquisition', title: 'GPT-driven', content: 'Seamless digital checkout is integrated into the curent experience - no page redirects or awkward checkout process for customers, one smooth experience' },
//   { id: 'mobile_ready_pos_dagdfm', title: 'Mobile-ready', content: 'We built the acquisition experience to be smooth on mobile devices, the primary channel for purchasing insurance for the next generation of users.' },
//   { id: 'landing_pages_new_fkmdmf', title: 'Landing pages', content: 'Provide a modern conversational experience that accurately represents your digital brand and reinforces trust while purchasing insurance confidently' }
// ];

const faqs = [
  {
    faq_intent: 'What does Indemn provide?',
    question: 'What does Indemn provide?',
  },
  {
    faq_intent: 'How do you help insurers?',
    question: 'How do you help insurers?',
  },
  {
    faq_intent: 'Will you offer help building our bot?',
    question: 'Will you offer help building our bot?',
  },
  {
    faq_intent: 'How do your AI Agents improve?',
    question: 'How do your AI Agents improve?',
  },
  {
    faq_intent: 'How does this impact sales?',
    question: 'How does this impact sales?',
  },
  {
    faq_intent: 'How do you loop in a human?',
    question: 'How do you loop in a human?',
  },
];

const smallFeatures1 = [
  {
    title: 'Quick start',
    content:
      'Pre-built agents deliver instant value with no development required',
  },
  {
    title: 'Custom solutions',
    content:
      'Build AI agents tailored to your unique workflows for maximum efficiency',
  },
  {
    title: 'Scalable flexibility',
    content: 'Start simple, then expand capabilities as your needs evolve',
  },
];

const smallFeatures2 = [
  {
    title: 'Real-Time data access',
    content:
      'Centralized knowledge base management to ensure that all your AI agents have up-to-date information',
  },
  {
    title: 'Streamlined automation',
    content:
      'Free your team from manual tasks like checking statuses or handling routine service requests',
  },
  {
    title: 'Omni-channel deployment',
    content:
      'Build AI agents to instantaneously respond in channels your users are most comfortable using',
  },
];

const smallFeatures3 = [
  {
    title: 'Speed to market',
    content:
      'AI agents proven to work and customizable for your business can be deployed in hours',
  },
  {
    title: 'Increased engagement',
    content:
      'Adding AI agents enables partners and customers friction-less access to your services and information',
  },
  {
    title: 'Increase in conversion',
    content:
      'With the ability to deliver quotes, payments and underwriting under a single, contextual experience, you sell more and higher-coverage policies at less cost',
  },
];

const mediumFeatures = [
  {
    title: 'Prompt engineering',
    content:
      'Make use of our expert AI agent building team and proven methods for delivering solutions to market.',
    media: 'md-prompt-engineering_jtphyt',
  },
  {
    title: 'Knowledge bases',
    content:
      'Use our innovative knowledge sharing system between agents to solve challenges across sales, service, billing, and more. ',
    media: 'md-multiple-kbs_tempe0',
  },
];

const Copilot = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.PRODUCTS_ACQUISITION.TITLE,
          constants.PAGE_CONTENT.PRODUCTS_ACQUISITION.DESCRIPTION,
          constants.PAGE_CONTENT.PRODUCTS_ACQUISITION.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.PRODUCTS_ACQUISITION.KEYWORDS,
        )}
        <GridContainer pt={20} pb={2}>
          <Container
            display="flex"
            flexDirection="column"
            margin="0 auto"
            gap={4}
            width={{ xs: 1, md: 7 / 8, xl: 6 / 12 }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Image
                src={constants.CLOUDINARY_INDEMN_LOGOS.PRODUCT_ACQUISITION_LOGO}
                alt="acquisition-logo"
                width={32}
                height={32}
                imageProps={{
                  style: {
                    objectFit: 'cover',
                  },
                }}
              />
              <Typography
                color="primary.800"
                sx={{ ...typography.base, ...typography.semibold }}
              >
                AI Studio
              </Typography>
            </Box>
            <MarkDown
              sx={{
                ...typography['4xl'],
                ...typography.bold,
                whiteSpace: 'pre-line',
              }}
              text="Build the future of insurance with our next-gen AI Studio"
            />
            <MarkDown
              sx={{ ...typography.base, ...typography.regular }}
              text="Whether you start with our pre-built AI agents or create your own, the AI Studio gives you the tools to build sophisticated, scalable solutions tailored to your business. Easy to learn, powerful to master—the possibilities are limitless."
            />
            <AskIndmenButton
              variant="contained"
              endIcon={<FiMessageCircle />}
              size="large"
              sx={{ width: 'fit-content' }}
              message="I’m interested in Indemn’s AI Studio"
            >
              Partner with us
            </AskIndmenButton>
          </Container>
        </GridContainer>

        <Box position="relative">
          <GridContainer pt={{ xs: 4, md: 6 }} zIndex={2}>
            <Box width={{ xs: 1, xl: 10 / 12 }} margin="0 auto">
              {isMobile() ? (
                <CloudImage id="acquisition-hero-img" />
              ) : (
                <CloudVideo id="acquisition-hero" />
              )}
            </Box>
          </GridContainer>
          {/* hero background */}
          <Image
            src={constants.CLOUDINARY_IMAGES.PRODUCT_HERO_IMAGE}
            alt="product-hero-image"
            position="absolute"
            top={0}
            zIndex={1}
            imageProps={{
              style: {
                opacity: 0.64,
                objectFit: 'cover',
                mixBlendMode: 'darken',
                filter: 'blur(8px)',
              },
            }}
          />
        </Box>
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        {/* itc-2024: replace this with large features */}
        <SectionFeatureLarge
          title="Create custom AI Agents or choose from proven pre-built solutions"
          content="Whether you're looking for a quick start with proven AI agents or want to build a highly customized solution, the AI Studio offers both. Pre-built agents are ready to support key functions like lead capture, service requests, and applications right out of the box. If your needs are more specific, our platform provides the flexibility to design AI agents tailored to your exact workflows."
          media="lg-broker-portal-assistant_qbyalj"
        />

        <SectionFeatureSmall features={smallFeatures1} />

        <SectionFeatureLarge
          title="Seamless API Integration for Real-Time Data Access"
          content="Connect your AI agents to key systems like policy management, CRM, claims, and underwriting with our dynamic REST APIs. Our platform processes real-time data instantly, ensuring your AI agents handle service requests, track claims, and provide up-to-date information without delay. Whether you're automating policy updates or generating quotes, our API integrations adapt to your workflows—letting you scale and customize with ease."
          media="lg-function-calling_v3i0m0"
        />

        <SectionFeatureSmall features={smallFeatures2} />

        <SectionFeatureMedium features={mediumFeatures} />

        <SectionFeatureSmall features={smallFeatures3} />

        <Faq faqs={faqs} />
        {/* {features.map(feature => (
          <GridContainer key={feature.id} display='flex' flexDirection='column' alignItems='center' gap={4}>
            <Box
              sx={{
                width: 1,
                borderRadius: 3,
                border: `1px solid ${palette.primary[200]}`,
              }}
            >
              <Container width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }} gap={2} margin='0 auto' display='flex' flexDirection='column'>
                <Typography color='primary.900' sx={{ ...typography['2xl'], ...typography.bold }}>
                  {feature.title}
                </Typography>
                <Typography color='primary.900' sx={{ ...typography.base, ...typography.regular, mixBlendMode: 'darken' }}>
                  {feature.content}
                </Typography>
              </Container>
            </Box>
            <Box width={{ xs: 1, xl: 8 / 12 }} margin="0 auto">
              <CloudImage id={`${feature.id}-img`} />
            </Box>
          </GridContainer>
        ))} */}
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Copilot;
