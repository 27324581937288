export function isMobile(): boolean {
  return /(iphone|android|blackberry|webos)/i.test(navigator.userAgent);
}

export function parseAdditionalSlots(str: string): Record<string, unknown> {
  if (!str) return {};

  try {
    const parsed = JSON.parse(str);
    if (
      typeof parsed === 'object' &&
      !Array.isArray(parsed) &&
      parsed !== null
    ) {
      return parsed;
    }
  } catch (err) {
    console.log('Failed to parse additional_slots: ', err);
  }
  return {};
}

export function parseNavItem(str: string): {
  icon: string;
  tag: string;
  text: string;
} {
  const matches = str.match(/\{.+\}/);
  const info = matches ? matches[0] : '';
  const text = str.replace(/\{.+\}/g, '').trim();

  if (info) {
    try {
      const parsed = JSON.parse(info);
      if (
        typeof parsed === 'object' &&
        !Array.isArray(parsed) &&
        parsed !== null
      ) {
        return { icon: '', tag: '', text, ...parsed };
      }
    } catch (err) {
      console.log('Failed to parse nav item: ', err);
    }
  }

  return { icon: '', tag: '', text };
}

export function scrollToTop(idStr: string) {
  const element = document.getElementById(idStr);
  if (!element) return;

  const topPosition = element.getBoundingClientRect().top + window.scrollY;

  window.scrollTo({
    top: topPosition - 128, // Specify the vertical position to scroll to
    behavior: 'smooth', // Use 'smooth' for smooth scroll behavior, or 'auto' for instant scroll
  });
}
