import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { Container, Image } from 'components';

interface FeatureProps {
  icon?: string;
  title: string;
  content: string;
}

const Feature = ({ icon, title, content }: FeatureProps): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Container>
      {icon ? (
        <Image
          src={icon}
          alt="feature-icon"
          width={48}
          height={48}
          sx={{ mb: { xs: 2.5, md: 4.5, xl: 4 } }}
        />
      ) : null}
      <Typography
        color="monochrome.gray.800"
        sx={{
          ...typography.base,
          ...typography.semibold,
          borderLeft: `2px solid ${palette.primary[600]}`,
          pl: { xs: 1, md: 2, xl: 3 },
          ml: { xs: -1, md: -2, xl: -3 },
        }}
      >
        {title}
      </Typography>
      <Typography
        color="monochrome.gray.600"
        sx={{ ...typography.base, ...typography.regular, mt: 2 }}
      >
        {content}
      </Typography>
    </Container>
  );
};

export default Feature;
