import React from 'react';
import { Typography, useTheme, BoxProps } from '@mui/material';
import Container from 'components/container';
import Partner from './partner';

interface TestimonialProp {
  recommendation: string;
  image: string;
  name: string;
  role: string;
  size?: string;
  href?: string;
}

const Testimonial = ({ recommendation, image, name, role, size = 'medium', href, ...boxProps }: TestimonialProp & BoxProps): JSX.Element => {
  const { typography } = useTheme();

  return (
    <Container display="flex" flexDirection="column" gap={3} {...boxProps}>
      <Typography color='primary.900' sx={{ ...typography.xl, ...typography.regular }}>
        {recommendation}
      </Typography>
      <Partner
        icon={image}
        name={name}
        description={role}
        size={size}
        nameColor='primary.900'
        descriptionColor='primary.900'
        href={href}
      />
    </Container>
  );
};

export default Testimonial;
