import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface Props {
  text: string;
  gradientColor?: string;
}

const GradientTypography = ({
  text,
  gradientColor,
  ...typographyProps
}: Props & TypographyProps) => {

  return (
    <Typography
      {...typographyProps}
      sx={{
        background: gradientColor,
        backgroundClip: 'text',
        textFillColor: 'transparent',
        ...typographyProps.sx,
      }}
    >
      {text}
    </Typography>
  );
};

export default GradientTypography;
