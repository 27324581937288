import React, { useContext } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { ChatbotContext } from 'context/chatbot-state';
import { scrollToTop } from 'utils/helper-generic';

declare global {
  interface Window {
    sendPayload?: (payload: string) => void;
    sendMessage?: (message: string) => void;
  }
}

interface Props {
  payload?: string;
  message?: string;
  children?: React.ReactNode;
}

const AskIndmenButton = ({ children, payload, message, ...buttonProps }: Props & ButtonProps): JSX.Element => {
  const { setAskIndemnActive } = useContext(ChatbotContext);

  const handleAsk = () => {
    setAskIndemnActive(true);
    const finalPayload = payload?.startsWith('/') ? payload : `/${payload}`;

    if (payload && window.sendPayload) {
      window.sendPayload(finalPayload); // Call global sendPayload method
    }
    if (message && window.sendMessage) {
      window.sendMessage(message); // Call global sendMessage method
    }
    scrollToTop('indemn-webchat');
  };

  return (
    <Button onClick={handleAsk} {...buttonProps}>
      {children}
    </Button>
  );
};

export default AskIndmenButton;