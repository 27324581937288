import React from 'react';
import { Box, Link, Typography, IconButton, useTheme } from '@mui/material';
import {
  FiTwitter,
  FiInstagram,
  FiFacebook,
  FiLinkedin,
  FiMail,
  FiMessageCircle,
} from 'react-icons/fi'; // Importing relevant icons
import { GridContainer, Logo, Divider, Container } from 'components';
import AskIndmenButton from 'views/components/ask-indemn-button';

const socialIcons = [
  {
    icon: FiTwitter,
    href: 'https://twitter.com/IndemnAi',
    ariaLabel: 'Link to Indemn\'s X or Twitter page',
  },
  {
    icon: FiInstagram,
    href: 'https://instagram.com/indemn.ai',
    ariaLabel: 'Link to Indemn\'s Instagram page',
  },
  {
    icon: FiFacebook,
    href: 'https://www.facebook.com/indemn.insurance',
    ariaLabel: 'Link to Indemn\'s Facebook page',
  },
  {
    icon: FiLinkedin,
    href: 'https://www.linkedin.com/company/indemn',
    ariaLabel: 'Link to Indemn\'s LinkedIn page',
  },
];

const links = {
  'Use cases': [
    { text: 'Broker Portal Assistant', href: '/solutions/broker-portal' },
    { text: 'AI CSR', href: '/solutions/ai-csr' },
    { text: 'Lead Capture', href: '/solutions/lead-capture' },
    {
      text: 'Online quotes and payments',
      href: '/solutions/online-quotes-payments',
    },
  ],
  Solutions: [
    { text: 'For agencies and brokers', href: '/solutions/agents-brokers' },
    { text: 'For MGAs and insurers', href: '/solutions/mga-insurers' },
    { text: 'For large enterprises', href: '/solutions/enterprise' },
  ],
  Company: [
    { text: 'About', href: '/about' },
    { text: 'Contact us', href: 'mailto:info@indemn.ai', icon: FiMail },
    { text: 'Data privacy', href: '/data-privacy' },
  ],
};

const FooterLinks = ({
  title,
  links = [],
}: {
  title: string;
  links: { text: string; href: string; icon?: React.ElementType; target?: string }[];
}): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Box>
      <Typography
        color="primary.300"
        sx={{ ...typography.sm, ...typography.medium }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1.5,
          ml: 0.75,
          gap: 0.75,
        }}
      >
        {links.map((e) => (
          <Link
            key={e.text}
            href={e.href}
            target={e.target}
            underline="none"
            component="a"
            color="white"
            sx={{
              ...typography.sm,
              ...typography.regular,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              '&:hover': {
                color: palette.tertiary[200],
              },
            }}
          >
            {e.text}
            {e.icon && <e.icon size={16} />}
          </Link>
        ))}
      </Box>
    </Box>
  );
};

const Footer = ({
  isExtended = false,
}: {
  isExtended?: boolean;
}): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Box sx={{ background: palette.background.footer }}>
      <GridContainer pt={4} pb={{ xs: 30, md: 6 }}>
        <Container
          sx={{
            display: isExtended ? 'flex' : 'none',
            flexDirection: 'column',
            gap: 3,
            width: { xs: 1, md: 6 / 8, xl: 7 / 12 },
          }}
        >
          <Typography
            variant="h3"
            color="primary.50"
            sx={{
              ...typography['4xl'],
              ...typography.bold,
              fontSize: {
                xs: typography['2xl'].fontSize,
                md: typography['4xl'].fontSize,
                xl: typography['3xl'].fontSize,
              },
            }}
          >
            Start your insurance conversation today
          </Typography>
          <Typography
            color="white"
            sx={{
              ...typography.base,
              ...typography.regular,
              fontSize: {
                xs: typography.base.fontSize,
                xl: typography.lg.fontSize,
              },
            }}
          >
            Test drive our conversational insurance platform and give yourself
            an edge in an AI-dominated future.
          </Typography>
          <AskIndmenButton
            variant="contained"
            endIcon={<FiMessageCircle />}
            size="large"
            color="secondary"
            sx={{ width: 'fit-content' }}
            message="I'm looking to power my business through Indemn"
          >
            Partner with us
          </AskIndmenButton>
        </Container>
        <Divider
          my={{ xs: 6, md: 4 }}
          display={isExtended ? 'block' : 'none'}
        />
        <Box
          sx={{
            display: { xs: 'flex', md: 'grid' },
            gridTemplateColumns: { xs: 'unset', md: '3fr 5fr', xl: '3fr 9fr' },
            flexDirection: { xs: 'column-reverse', md: 'unset' },
          }}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mt: { xs: 6, md: 0 },
            }}
          >
            <Logo isDark />
            <Typography
              color="monochrome.zinc.200"
              sx={{ ...typography.xs, ...typography.regular }}
            >
              5 Union Square West, #1383
              <br />
              New York, NY 10003
              <br />
              <br />
              NY License #BR-1665925
            </Typography>
            <Box display="flex" alignItems="center" gap={1.5}>
              {socialIcons.map(({ icon: Icon, href, ariaLabel }) => (
                <IconButton
                  href={href}
                  key={href}
                  sx={{ p: 0 }}
                  target="_blank"
                  aria-label={ariaLabel}
                >
                  <Icon color="white" />
                </IconButton>
              ))}
            </Box>
            <Typography
              color="monochrome.zinc.200"
              sx={{ ...typography.xs, ...typography.regular }}
            >
              © {new Date().getFullYear()} Indemn Inc. All rights reserved.
            </Typography>
          </Container>
          <Container
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                md: '1fr 1fr',
                xl: '1fr 1fr 1fr',
              },
              gap: { xs: 2.5, md: 4.5, xl: 6.5 },
            }}
          >
            {Object.keys(links).map((key) => (
              <FooterLinks key={key} title={key} links={links[key]} />
            ))}
          </Container>
        </Box>
      </GridContainer>
    </Box>
  );
};

export default Footer;
