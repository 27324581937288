import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import {
  SectionFeatureSmall,
  SectionFeatureLarge,
  SectionFeatureMedium,
  SectionHero1,
  AskIndmenButton,
  BotHostContainer,
  Faq,
} from 'views/components';
import SectionVoice from 'views/components/section-voice';
import { GridContainer, Container } from 'components';
import { FiMessageCircle } from 'react-icons/fi'; // Importing react-icons
import { useTheme, Typography } from '@mui/material';
import ChatLoader from 'context/chatLoader';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const smallFeatures1 = [
  {
    media: 'sm-25-conversion_m52edw',
    title: 'Record-breaking 25% conversion',
    content:
      'We’ve proven that the conversational method for selling insurance obliterates conventional webform conversion',
  },
  {
    media: 'sm-71-users-to-quote_qsiuwk',
    title: 'Engage to Quote',
    content:
      'Leverage our conversational AI technology to convert more policies when agents are interacting with the system',
  },
  {
    media: 'sm-60-cross-sell_liuat8',
    title: '60% conversion on cross-sell and buy-up',
    content:
      'Displaying multiple quote options led to 60% of customers purchasing a more comprehensive package.',
  },
];

const smallFeatures2 = [
  {
    title: 'Personalized distribution',
    content:
      'Drive three times the conversion on cross-sell efforts with a personalized experience for every user.',
  },
  {
    title: 'Multibrand engagement',
    content:
      'Provide contextually relevant answers across your brands and manage communications in one place.',
  },
  {
    title: 'Leverage automated agent testing',
    content:
      'We’ve perfected the dev and deploy process for AI agents, giving you greater confidence about how your AI agents perform in production.',
  },
];

const mediumFeatures1 = [
  {
    title: 'Omni-channel setup to deliver instant responses',
    content:
      'Deliver a game-changing experience with AI that provides a seamless conversational interface across all channels.',
    media: 'md-omnichannel_rbcbfa',
  },
  {
    title: 'Deploy contextually across distribution channels',
    content:
      'Our AI agents adapt to your workflow, and recommend contextually appropriate responses based on the conversation.',
    media: 'md-distribution_iper0t',
  },
];

const faqs = [
  {
    faq_intent: 'What does Indemn provide?',
    question: 'What does Indemn provide?',
  },
  {
    faq_intent: 'How do you help insurers?',
    question: 'How do you help insurers?',
  },
  {
    faq_intent: 'How does an AI Agent impact sales?',
    question: 'How does an AI Agent impact sales?',
  },
  {
    faq_intent: 'How do your AI Agents improve?',
    question: 'How do your AI Agents improve?',
  },
  {
    faq_intent: 'Do you build custom bots?',
    question: 'Do you build custom bots?',
  },
  {
    faq_intent: 'How do you loop in a human?',
    question: 'How do you loop in a human?',
  },
];

const Enterprise = (): JSX.Element => {
  const { typography } = useTheme();
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_ENTERPRISE.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_ENTERPRISE.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_ENTERPRISE.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_ENTERPRISE.KEYWORDS,
        )}
        <SectionHero1
          title="AI solutions for the most complex workflows, built to scale"
          content="Built to scale with your business, we automate complex processes, manage high volumes of interactions, and provide the infrastructure to drive efficient operations—ensuring your enterprise achieves lasting transformation."
          message="I’d like to see what AI solution might fit my needs"
        />

        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        <SectionFeatureMedium features={mediumFeatures1} />

        <SectionVoice></SectionVoice>

        <SectionFeatureLarge
          title="Custom AI models for your business"
          content="Leverage the flexibility of our platform with interchangeable Large Language Models (LLMs) that adapt to your unique needs. Our AI models can be housed within your own environment, ensuring data security and compliance, while our team builds custom solutions from the ground up, designed specifically for your business’s workflows and use cases."
          media="lg-function-calling-02_miznmz"
        />

        <SectionFeatureSmall features={smallFeatures1} />

        <SectionFeatureLarge
          title="Generative AI as a Service"
          content="We do more than provide tools-we collaborate with MGAs and insurers to build tailored, generative-AI-powered solutions that fit your specific business needs. From optimizing current workflows to creating new opportunities, our team will work with yours to unlock the full potential of AI."
          media=""
        />

        <Faq faqs={faqs} />

        <SectionFeatureSmall features={smallFeatures2} />

        <GridContainer>
          <Container
            display="flex"
            flexDirection="column"
            gap={4}
            width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
          >
            <Typography
              color="primary.900"
              sx={{ ...typography['4xl'], ...typography.bold }}
            >
              Let’s develop a solution together
            </Typography>
            <Typography
              variant="h5"
              color="primary.900"
              sx={{ ...typography.base, ...typography.regular }}
            >
              We build custom conversational AI systems for our customers. Reach
              out to our Partnerships team to learn how we can collaborate to
              tailor the perfect solution for your enterprise.
            </Typography>
            <AskIndmenButton
              variant="contained"
              endIcon={<FiMessageCircle />}
              size="large"
              sx={{ width: 'fit-content' }}
            >
              Talk to us
            </AskIndmenButton>
          </Container>
        </GridContainer>
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default Enterprise;
