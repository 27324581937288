import React from 'react';
import {
  Routes as ReactRoutes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';
import viewsRoutes from 'views/routes';
import redirects from 'config/301-redirects.json';

const Routes = (): JSX.Element => {
  const isProduction = process.env.REACT_APP_ENV === 'PRODUCTION';

  return (
    <ReactRoutes>
      {/* Production redirect for all /partners and /venues paths */}
      {isProduction ? (
        <>
          <Route
            path="partners/:id"
            element={
              <ExternalRedirect base="https://www.eventguard.ai/partners" />
            }
          />
          <Route
            path="venues/:id"
            element={
              <ExternalRedirect base="https://www.eventguard.ai/venues" />
            }
          />
        </>
      ) : (
        // Development environment redirects from 301-redirects.json
        Object.keys(redirects).map((oldPath) => (
          <Route
            key={oldPath}
            path={oldPath}
            element={<Navigate to={redirects[oldPath]} replace />}
          />
        ))
      )}

      {/* Render other views */}
      {viewsRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}

      {/* 404 Not Found route */}
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </ReactRoutes>
  );
};

const ExternalRedirect = ({ base }: { base: string }) => {
  const location = useLocation();

  React.useEffect(() => {
    const newUrl = `${base}${location.pathname.replace(
      /^(\/partners|\/venues)/,
      '',
    )}${location.search}`;
    window.location.href = newUrl;
  }, [base, location]);

  return null;
};

export default Routes;
