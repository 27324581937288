import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import {
  SectionFeatureSmall,
  SectionFeatureMedium,
  SectionFeatureLarge,
  SectionHero1,
  BotHostContainer,
  Faq,
} from 'views/components';
import ChatLoader from 'context/chatLoader';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const mediumFeatures1 = [
  {
    title: 'Reduce time spent on repeated queries',
    content:
      'Empower your team to avoid context-switching and focus on high-priority tasks as our AI agents take care of the routine queries.',
    media: 'lg-service-reduction-01_d04wqo',
  },
  {
    title: 'Learns from every interaction',
    content:
      'Over time, our AI agents become more autonomous, perfectly tuned to your agency’s needs.',
    media: 'md-rlhf_e66nrb',
  },
];

const mediumFeatures2 = [
  {
    title: 'Omni-channel setup to deliver instant responses',
    content:
      'Deliver a game-changing experience with AI that provides a seamless conversational interface across all channels.',
    media: 'md-omnichannel_rbcbfa',
  },
  {
    title: 'Recommendations based on your knowledge base',
    content:
      'Our AI agents adapt to your workflow, and recommend contextually appropriate responses based on the conversation.',
    media: 'md-ai-recommendations_euygim',
  },
];

const faqs = [
  {
    faq_intent: 'What does Indemn provide?',
    question: 'What does Indemn provide?',
  },
  {
    faq_intent: 'How do you help brokers?',
    question: 'How do you help brokers?',
  },
  {
    faq_intent: 'How would this improve my website?',
    question: 'How would this improve my website?',
  },
  {
    faq_intent: 'How do your AI Agents improve?',
    question: 'How do your AI Agents improve?',
  },
  {
    faq_intent: 'How do you help Agents?',
    question: 'How do you help Agents?',
  },
  {
    faq_intent: 'How do you loop in a human?',
    question: 'How do you loop in a human?',
  },
];

const mediumFeatures3 = [
  {
    title: 'Live intervention',
    content:
      'Leverage live human-handoff to your CSRs for escalated requests, including setting up notifications on all channels your team monitors.',
    media: 'md-live-intervention_bqv95r',
  },
  {
    title: 'Deliver knowledge from multiple sources accessibly',
    content:
      'Provide your users a single place to access key info that may be inaccessible through conventional web navigation or complex call trees.',
    media: 'md-multiple-kbs_tempe0',
  },
];

const smallFeatures = [
  {
    // media: 'sm-sample-02',
    title: 'Higher engagement',
    content:
      'Deliver an instantly responsive and engaging experience, across communications channels, leading to increased activity and user satisfaction.',
  },
  {
    // media: 'sm-sample-01',
    title: 'Flexible automation',
    content:
      'Set up data automations through our fluid API integrations, avoiding your team the hassle of referring to multiple data sources and screens.',
  },
  {
    // media: 'sm-sample-02',
    title: 'Increased team capacity',
    content:
      'Increase your team\'s capacity by upto 5x by having a round-the-clock AI handle repetitive tasks and initial customer interactions.',
  },
];

const AgencyBroker = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_AGENCIES.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_AGENCIES.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_AGENCIES.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_AGENCIES.KEYWORDS,
        )}
        <SectionHero1
          title="Next-generation Sales and Service solution for Agencies and Brokers"
          content="Leverage AI to handle customer inquiries, automate policy renewals, and streamline the sales process—allowing your agency to serve more clients and grow faster."
          message="I’d like to see what AI solution might fit my needs"
          // media="hero-sample"
        />

        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        <SectionFeatureMedium features={mediumFeatures1} />

        <Faq faqs={faqs} />

        <SectionFeatureMedium features={mediumFeatures2} />

        {/* <SectionFeatureLarge
          title="Drive engagement, boost conversions, and grow revenue"
          content="Say goodbye to outdated webforms and hello to a dynamic conversation that captures leads more effectively. Improve form completion, customize intake to your products and watch your book grow faster."
          media="lg-sample-01"
        /> */}

        <SectionFeatureSmall features={smallFeatures} />

        <SectionFeatureLarge
          title="AMS, CRM, and Carrier integrations"
          content="Integrated APIs to your policy management and CRM, our AI CSRs can recognize policy level information and automatically process service requests, freeing up the team to tackle higher priority tasks."
          media="lg-function-calling-02_miznmz"
        />

        <SectionFeatureMedium features={mediumFeatures3} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default AgencyBroker;
