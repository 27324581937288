import React from 'react';
import { Box, Button, useTheme, Typography } from '@mui/material';
import { GridContainer, Container, Image } from 'components';
import { FiMail } from 'react-icons/fi';
import * as constants from 'utils/constants';

const team = [
  {
    name: 'Kyle Geoghan',
    role: 'Co-founder, CEO',
    avatar: constants.CLOUDINARY_IMAGES.TEAM_KYLE_GEOGHAN_PROFILE_IMAGE,
    title: 'Insurance Champion',
  },
  {
    name: 'Patrick Daly',
    role: 'Co-founder, CTO',
    avatar: constants.CLOUDINARY_IMAGES.TEAM_PATRICK_DALY_PROFILE_IMAGE,
    title: 'Technology Champion',
  },
];

const Team = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display="flex" flexDirection="column" gap={2} id="team">
      <Container display="flex" flexDirection="column" gap={2}>
        <Typography
          color="primary.900"
          sx={{ ...typography['2xl'], ...typography.bold }}
        >
          Team
        </Typography>
        <Typography
          color="primary.900"
          sx={{ ...typography.base, ...typography.regular }}
        >
          We value drive and teamwork — join us!
        </Typography>
        <Button
          variant="outlined"
          endIcon={<FiMail size={12} />}
          color="inherit"
          size="small"
          sx={{ width: 'fit-content' }}
          href="mailto:careers@indemn.ai"
        >
          Reach out
        </Button>
      </Container>
      <Box
        display="grid"
        gridTemplateColumns={{ xs: '1f', md: '1fr 1fr', xl: '1fr 1fr 1fr' }}
        gap={0.5}
      >
        {team.map((member) => (
          <Container key={member.name}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              padding={1.5}
            >
              <Image
                src={member.avatar}
                alt={member.name}
                width={180}
                height={180}
                imageProps={{
                  style: {
                    objectFit: 'containe',
                    borderRadius: 32,
                  },
                }}
              />
              <Box textAlign="center">
                <Typography
                  color="monochrome.gray.800"
                  sx={{ ...typography.base, ...typography.medium, mb: 1 }}
                >
                  {member.name}
                </Typography>
                <Typography
                  color="monochrome.gray.600"
                  sx={{ ...typography.sm, ...typography.regular }}
                >
                  {member.role}
                  <br />
                  {member.title}
                </Typography>
              </Box>
            </Box>
          </Container>
        ))}
      </Box>
    </GridContainer>
  );
};

export default Team;
