import React from 'react';
import { useTheme } from '@mui/material';
import { CloudImage, Container, GridContainer, MarkDown } from 'components';

interface FeatureMedium {
  title: string;
  content?: string;
  media: string;
}

const SectionFeatureMedium = ({
  features,
}: {
  features: FeatureMedium[];
}): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gap: { xs: 4, md: 0.5 },
      }}
    >
      {features.map((feature) => (
        <Container
          key={feature.title}
          display="flex"
          flexDirection="column"
          gap={4}
        >
          <MarkDown
            sx={{ ...typography['xl'], ...typography.bold }}
            text={feature.title}
          />
          {!!feature.content && (
            <MarkDown
              sx={{ ...typography.base, ...typography.regular }}
              text={feature.content}
            />
          )}
          <CloudImage id={feature.media} />
        </Container>
      ))}
    </GridContainer>
  );
};

export default SectionFeatureMedium;
