import { useEffect, useState } from 'react';

export const useFAQ = (faq_table?: string, faq_list?: string) => {
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const allFaqs = await (
          await fetch(
            `${process.env.REACT_APP_CONVERSATION_URL}/faq/${faq_table}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'app-access-token':
                  process.env.REACT_APP_CONVERSATION_ACCESS_TOKEN,
              },
            },
          )
        ).json();

        if (faq_list) {
          setFaqs(
            faq_list
              .split(',')
              .map((intent) =>
                allFaqs.find((f) => f.faq_intent === intent.trim()),
              )
              .filter(Boolean),
          );
        } else {
          setFaqs(allFaqs);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (faq_table) {
      fetchFAQs();
    }
  }, [faq_table, faq_list]);

  return { faqs };
};
