import React from 'react';
import { Box, useTheme, BoxProps } from '@mui/material';

const Divider = (boxProps: BoxProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Box
      {...boxProps}
      sx={{
        borderTop: `1px dotted ${palette.primary[600]}`,
        ...boxProps.sx
      }}
    />
  );
};

export default Divider;
