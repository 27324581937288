import React from 'react';
import { CloudImage, Container, GridContainer } from 'components';
import { Stack, Typography, useTheme } from '@mui/material';

interface TSectionFeatureSmall {
  title: string;
  content?: string;
  media?: string;
}

const SectionFeatureSmall = ({
  features,
}: {
  features: TSectionFeatureSmall[];
}): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <GridContainer
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
        gap: { xs: 1.5, xl: 0.5 },
      }}
    >
      {features.map((feature) => (
        <Container
          key={feature.title}
          display="flex"
          flexDirection="column"
          gap={{ xs: 2.5, md: 4.5, xl: 4 }}
        >
          {!!feature.media && <CloudImage id={feature.media} />}
          <Stack gap={2}>
            <Typography
              color="monochrome.gray.800"
              sx={{
                ...typography.base,
                ...typography.semibold,
                borderLeft: `2px solid ${palette.primary[600]}`,
                pl: { xs: 1, md: 2, xl: 3 },
                ml: { xs: -1, md: -2, xl: -3 },
              }}
            >
              {feature.title}
            </Typography>
            <Typography
              color="monochrome.gray.600"
              sx={{ ...typography.base, ...typography.regular }}
            >
              {feature.content}
            </Typography>
          </Stack>
        </Container>
      ))}
    </GridContainer>
  );
};

export default SectionFeatureSmall;
