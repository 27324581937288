import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

const GridContainer = ({ children, ...rest }: Props & BoxProps): JSX.Element => (
  <Box
    width={1}
    position="relative"
    margin="0 auto"
    maxWidth={{ xs: 1, xl: 1216 }}
    px={{ xs: 2, md: 4, xl: 0 }}
    pb={{ xs: 12, md: 16 }}
    {...rest}
  >
    {children}
  </Box>
);

export default GridContainer;
