import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import {
  SectionFeatureMedium,
  SectionFeatureLarge,
  SectionHero1,
  BotHostContainer,
  Faq,
} from 'views/components';
import ChatLoader from 'context/chatLoader';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const mediumFeatures1 = [
  {
    title: 'Online quotes',
    content:
      'Best-in-class embedded quote experience that integrates seamlessly with your ratings table and will provide a demonstrable lift in your inbound conversion rates.',
    media: 'md-quotes_vzpbj7',
  },
  {
    title: 'Built-in payments',
    content:
      'Deliver a in-built payment experience at checkout without navigating away, improving bind rates.',
    media: 'md-payments_w3tvti',
  },
];

const mediumFeatures2 = [
  {
    title: 'Live intervention',
    content:
      'Leverage live human-handoff to your CSRs for escalated requests, including setting up notifications on all channels your team monitors.',
    media: 'md-live-intervention_bqv95r',
  },
  {
    title: 'Manage knowledge sources independently',
    content:
      'Our AI agents adapt to your workflow, and recommend contextually appropriate responses based on the conversation.',
    media: 'md-multiple-kbs_tempe0',
  },
];

const faqs = [
  {
    faq_intent: 'What does Indemn provide?',
    question: 'What does Indemn provide?',
  },
  {
    faq_intent: 'How do you help insurers?',
    question: 'How do you help insurers?',
  },
  {
    faq_intent: 'How would this improve my website?',
    question: 'How would this improve my website?',
  },
  {
    faq_intent: 'How do your AI Agents improve?',
    question: 'How do your AI Agents improve?',
  },
  {
    faq_intent: 'How does this impact sales?',
    question: 'How does this impact sales?',
  },
  {
    faq_intent: 'How do you loop in a human?',
    question: 'How do you loop in a human?',
  },
];

// const smallFeatures = [
//   {
//     media: 'sm-sample-02',
//     title: 'Record-breaking 25% conversion',
//     content:
//       'We’ve proven that the conversational method for selling insurance obliterates conventional webform conversion',
//   },
//   {
//     media: 'sm-sample-01',
//     title: 'Engage to Quote',
//     content:
//       'Leverage our conversational AI technology to convert more policies when agents are interacting with the system',
//   },
//   {
//     media: 'sm-sample-02',
//     title: '60% conversion on cross-sell and buy-up',
//     content:
//       'Displaying multiple quote options led to 60% of customers purchasing a more comprehensive package.',
//   },
// ];

const MGACarriers = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_MGAS.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_MGAS.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_MGAS.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_MGAS.KEYWORDS,
        )}
        <SectionHero1
          title="GenAI-powered sales, service, and distribution for MGAs & Insurers"
          content="Support appointed brokers, create direct and embedded channel experiences, and optimize customer service—all within a single platform."
          message="I’d like to see what AI solution might fit my needs"
        />
        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />
        <SectionFeatureLarge
          title="Do you wish your agents could automate repetitive service requests and sell more policies?"
          content="Implement the Broker Portal Assistant in just hours and immediately enhance service for your appointed agents. This simple AI agent automates FAQs, processes service requests, and sets you on the path to building more advanced AI agents."
          media="lg-broker-portal-assistant_qbyalj"
        />

        <Faq faqs={faqs} />

        <SectionFeatureLarge
          title="Generative AI as a Service"
          content="We do more than provide tools-we collaborate with MGAs and insurers to build tailored, generative-AI-powered solutions that fit your specific business needs. From optimizing current workflows to creating new opportunities, our team will work with yours to unlock the full potential of AI."
          media=""
        />

        <SectionFeatureMedium features={mediumFeatures1} />
        <SectionFeatureMedium features={mediumFeatures2} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default MGACarriers;
