import React from 'react';
import Main from 'layouts/main';
import ChatLoader from 'context/chatLoader';
import {
  SectionFeatureSmall,
  SectionFeatureMedium,
  SectionHero1,
  AskIndmenButton,
} from 'views/components';

import { GridContainer, Container } from 'components';
import { FiMessageCircle } from 'react-icons/fi'; // Import from react-icons for better modularity
import { useTheme, Typography } from '@mui/material';
import * as constants from 'utils/constants';
import { generateMetaTags } from '../pageHelmet';

const smallFeatures = [
  {
    title: 'Never stored for LLM training',
    content:
      'Your Personal Identifying Information (PII) is never stored as a reference for LLM training.',
  },
  {
    title: 'Deleted Upon Request',
    content:
      'Data can be removed from storage depending on each customers needs.',
  },
  {
    title: 'Data Not for Sale',
    content:
      'Your data is for you. It will only be used to improve YOUR model, not for our profit.',
  },
];

const mediumFeatures = [
  {
    media: '',
    title: 'Our Commitment to Data Security',
    content:
      'We understand the importance of protecting your sensitive information. That’s why we have implemented a comprehensive set of security measures to keep your data safe at all times.',
  },
  {
    media: '',
    title: 'Your Privacy, Our Priority',
    content:
      'We believe in transparency and user control when it comes to personal data. Our privacy practices are designed to give you full control over how your information is collected, used, and stored.',
  },
];

const DataPrivacy = (): JSX.Element => {
  const { typography } = useTheme();
  return (
    <Main isExtended>
      {generateMetaTags(
        constants.PAGE_CONTENT.DATA_PRIVACY.TITLE,
        constants.PAGE_CONTENT.DATA_PRIVACY.DESCRIPTION,
        constants.PAGE_CONTENT.DATA_PRIVACY.OG_IMAGE,
        window.location.href,
        constants.PAGE_CONTENT.DATA_PRIVACY.KEYWORDS,
      )}
      <SectionHero1
        title="Data Privacy"
        content="Founded as an insurance company, we take data security seriously and don’t share or sell any personal identifying information."
      />

      <SectionFeatureMedium features={mediumFeatures} />

      <SectionFeatureSmall features={smallFeatures} />

      <GridContainer>
        <Container
          display="flex"
          flexDirection="column"
          gap={4}
          width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
        >
          <Typography
            color="primary.900"
            sx={{ ...typography['2xl'], ...typography.bold }}
          >
            Require additional compliance protocols?
          </Typography>
          <Typography
            variant="h5"
            color="primary.900"
            sx={{ ...typography.base, ...typography.regular }}
          >
            Chat with our AI agent to let us know what protocols you need
            compliance with and we’ll let you know if we can serve your needs.
          </Typography>
          <AskIndmenButton
            variant="contained"
            endIcon={<FiMessageCircle />}
            size="large"
            sx={{ width: 'fit-content' }}
          >
            Talk to us
          </AskIndmenButton>
        </Container>
      </GridContainer>

      <ChatLoader />
    </Main>
  );
};

export default DataPrivacy;
