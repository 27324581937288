import React from 'react';
import { useTheme, Typography, Box } from '@mui/material';
import { FiMessageCircle } from 'react-icons/fi'; // Importing react-icons
import { GridContainer, Container, Image, MarkDown } from 'components';
import { AskIndmenButtonOld, ExternalLink } from 'views/components';
// import { GoogleMap, Marker } from '@react-google-maps/api';
import { useMap } from 'hooks/use-map';
import * as constants from 'utils/constants';

const Hero = ({ venue }: { venue: Venue }): JSX.Element => {
  const { typography, shadows } = useTheme();
  const { img } = useMap(`${venue.venue_name} ${venue.venue_address}`);

  // const center = useMemo(() => ({ lat, lng }), [lat, lng]);
  const hero_img = venue.hero_img ?? img;

  return (
    <>
      {/* hero background */}
      <Image
        src={constants.CLOUDINARY_IMAGES.PARTNER_US_HERO_IMAGE}
        alt="partner-us-hero-image"
        sx={{
          position: 'absolute',
          width: { xs: '116vh', md: '112vh', xl: '140vh' },
          height: { xs: '116vh', md: '112vh', xl: '140vh' },
          right: { xl: '-37.5vh' },
          top: { xs: '42vh', md: '12vh', xl: '-35vh' },
        }}
        imageProps={{
          style: {
            objectFit: 'cover',
            mixBlendMode: 'darken',
            opacity: 0.8,
          },
        }}
      />

      <GridContainer
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', xl: 'row' },
          pt: 16,
        }}
      >
        <Container
          display="flex"
          flexDirection="column"
          gap={4}
          width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
        >
          <MarkDown
            sx={{
              ...typography['4xl'],
              ...typography.bold,
              whiteSpace: 'pre-line',
            }}
            text={
              venue.hero_heading ??
              `Protect your perfect day at \n*${venue.venue_name}*`
            }
          />
          <MarkDown
            sx={{ ...typography.lg, ...typography.regular }}
            text={
              venue.hero_body ??
              'Get the perfect coverage in minutes and at the best value through an intuitive and automated self-serve experience.'
            }
          />
          <AskIndmenButtonOld
            variant="contained"
            endIcon={<FiMessageCircle />}
            size="large"
            sx={{ width: 'fit-content' }}
          >
            {venue.cta_label ?? 'Get quote'}
          </AskIndmenButtonOld>
        </Container>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          width={{ xs: 1, md: 6 / 8, xl: 6 / 12 }}
          alignSelf={{ xs: 'center', xl: 'flex-start' }}
          mt={{ xs: 4, md: 6, xl: 0 }}
        >
          {!!hero_img && (
            <Image
              src={hero_img}
              alt={venue.venue_name}
              height={{ xs: '36vh', md: '30vh', xl: '33vh' }}
              imageProps={{
                style: {
                  objectFit: 'cover',
                  borderRadius: 12,
                  boxShadow: shadows[1],
                },
              }}
            />
          )}
          {!!venue.venue_name && (
            <Box
              sx={{
                borderRadius: 3,
                border: '1px solid rgba(255, 255, 255, 0.32)',
                background:
                  'linear-gradient(155deg, rgba(255, 255, 255, 0.94) 36.16%, rgba(255, 255, 255, 0.20) 85.22%)',
                boxShadow: shadows[1],
              }}
            >
              <Container display="flex" flexDirection="column" gap={2}>
                <Typography
                  color="primary.900"
                  sx={{ ...typography.base, ...typography.semibold }}
                >
                  {venue.venue_name}
                </Typography>
                {!!venue.venue_address && (
                  <Typography
                    color="primary.900"
                    sx={{ ...typography.sm, ...typography.regular }}
                  >
                    {venue.venue_address}
                  </Typography>
                )}
                <Box display="flex" alignItems="center" gap={4}>
                  {!!venue.venue_phone && (
                    <Typography
                      color="primary.900"
                      sx={{ ...typography.sm, ...typography.regular }}
                    >
                      {venue.venue_phone}
                    </Typography>
                  )}
                  {!!venue.form_venue_email && (
                    <Typography
                      color="primary.900"
                      sx={{ ...typography.sm, ...typography.regular }}
                    >
                      {venue.form_venue_email}
                    </Typography>
                  )}
                  {!!venue.venue_website_url && (
                    <ExternalLink
                      href={venue.venue_website_url}
                      ariaLabel={`Visit the website for ${venue.venue_name}`}
                    />
                  )}
                </Box>
              </Container>
              {/* {!!(lat && lng) && (
                <Box height={{ xs: '36vh', md: '30vh', xl: '33vh' }}>
                  <GoogleMap
                    mapContainerStyle={{ height: '100%' }}
                    center={center}
                    zoom={10}
                  >
                    <Marker position={{ lat, lng }} />
                  </GoogleMap>
                </Box>
              )} */}
            </Box>
          )}
        </Box>
      </GridContainer>
    </>
  );
};

export default Hero;
