import React from 'react';
import Main from 'layouts/main';
import ChatLoader from 'context/chatLoader';
import {
  SectionFeatureSmall,
  SectionFeatureMedium,
  SectionFeatureLarge,
  SectionHero1,
  BotHostContainer,
  AskIndmenButton,
} from 'views/components';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import { GridContainer, Container } from 'components';
import { FiMessageCircle } from 'react-icons/fi'; // Importing react-icons
import { useTheme, Typography } from '@mui/material';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const mediumFeatures1 = [
  {
    title: 'Live intervention',
    content:
      'Leverage live human-handoff to your CSRs for escalated requests, including setting up notifications on all channels your team monitors.',
    media: 'md-live-intervention_bqv95r',
  },
  {
    title: 'AI-powered recommendations',
    content:
      'Save significant time and improve accuracy with AI recommendations informed by your policies, procedures and product information.',
    media: 'md-ai-recommendations_euygim',
  },
];

const smallFeatures1 = [
  {
    media: 'sm-webchat_d2uuxe',
    title: 'Webchat',
    content:
      'Deploy a webchat providing instant responses, built-in with human-handoff to your online CSRs.  Our conversational method has shown massive improvement in engagement metrics when compared to webforms.',
  },
  {
    media: 'sm-phone_is0aso',
    title: 'Voice',
    content:
      'Let our voice agents leverage the same knowledge sources and APIs to deliver channel-suitable responses before redirecting to your CSR team.',
  },
  {
    media: 'sm-email-sms_rmxurl',
    title: 'Email and text messaging',
    content:
      'Deliver instant responses, 24/7/365 through email and SMS/MMS and create follow-up tickets for your CSR team.',
  },
];

const BrokerPortal = (): JSX.Element => {
  const { typography } = useTheme();
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_BROKER.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_BROKER.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_BROKER.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_BROKER.KEYWORDS,
        )}
        <SectionHero1
          title="Empower your agents with the Broker Portal Assistant"
          content="Automate routine tasks, improve agent engagement, and reduce workloads with AI-driven support built for insurance pros. Help your appointed agents focus on what matters most—**building client relationships**."
          media="hero-broker-portal-assistant_tc5vto"
          message="I’m interested in the Broker Portal Assistant"
        />

        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        <SectionFeatureLarge
          title="Boost agent engagement"
          content="**Answer your agent’s questions immediately**. Enable friction-less access to information. Get newly appointed agents up to speed faster, and more loyal to your products. **Drive more revenue** from engaged partners who know what they need to. "
          media="lg-service-reduction-02_nxotqh"
        />

        <SectionFeatureSmall features={smallFeatures1} />

        <SectionFeatureLarge
          title="Automating human interventions through dynamic APIs"
          content="Set up automated workflows triggered by a request made in natural language, thereby automating over 50% of typical human interventions, like checking and updating policy status. With our API capabilities, you can build similar flows, reducing the workload on your team and improving response times."
          media="lg-function-calling-02_miznmz"
        />
        <GridContainer>
          <Container
            display="flex"
            flexDirection="column"
            gap={4}
            width={{ xs: 1, md: 6 / 8, xl: 7 / 12 }}
          >
            <Typography
              color="primary.900"
              sx={{ ...typography['4xl'], ...typography.bold }}
            >
              Go from 10% to over 50% of AI agent coverage in 60 days
            </Typography>
            <Typography
              variant="h5"
              color="primary.900"
              sx={{ ...typography.base, ...typography.regular }}
            >
              When you sign up for the Broker Portal Assistant, we work with you
              to rapidly improve the AI model, achieving over 50% coverage of
              agent questions within 60 days. By leveraging AI feedback
              mechanisms, summarization, and categorization, this system frees
              your team to focus on more complex tasks.
            </Typography>
            <AskIndmenButton
              variant="contained"
              endIcon={<FiMessageCircle />}
              size="large"
              sx={{ width: 'fit-content' }}
            >
              Talk to us
            </AskIndmenButton>
          </Container>
        </GridContainer>
        <SectionFeatureMedium features={mediumFeatures1} />
        <SectionFeatureLarge
          title="Data-driven insights and workflows"
          content="Every conversation your AI agent handles is automatically categorized and summarized, giving you a clear view of what agents care about and need to do. This allows us to identify more complex workflows that require dynamic data. From there, we build APIs to tackle those workflows, enabling your agents to go beyond simple inquiries. By understanding agent behavior, we’re able to deliver the data and solutions they need to get the job done efficiently."
          media="lg-analytics_iyfwbj"
        />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default BrokerPortal;
