const shadows = (): [
  'none',
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
] => {
  return [
    'none',
    '3.6px 3.5px 6px -2px rgba(145, 145, 145, 0.47), 1.5px 1.5px 2.5px -1.3px rgba(145, 145, 145, 0.42), 0.6px 0.6px 1px -0.7px rgba(145, 145, 145, 0.38), 0.2px 0.2px 0.3px 0px rgba(145, 145, 145, 0.34)',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];
};

export default shadows;
