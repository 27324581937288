import React, { createContext, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

type ChatOpenProps = {
  isFirstUserInteraction: boolean;
};

type ChatbotState = {
  isChatOpen: boolean;
  scriptLoaded: boolean;
};

type UserTools = {
  [keyName: string]: {
    display: boolean;
  };
};

type LauncherConfig = {
  useLauncher?: boolean;
  launcherSize?: number;
  launcherType?: 'icon-label' | 'icon';
  launcherLabel?: string;
  botPosition?: 'bottom-right' | 'bottom-left';
}

export let chatOpen: (props?: ChatOpenProps) => void;
export let sendMessage: (string) => void;
export let sendPayload: (string) => void;

interface ChatbotStoreProviderProps {
  children: React.ReactNode;
  initPayload?: string;
  subtitle?: string;
  embeded?: boolean;
  botType?: string;
  showSummary?: boolean;
  userTools?: UserTools;
  launcherConfig?: LauncherConfig;
}

const initialState = {
  isChatOpen: false,
  scriptLoaded: false,
};

export const ChatbotContextOld = createContext<{ chatbotState: ChatbotState }>({
  chatbotState: initialState,
});

export const ChatbotStoreProviderOld = (props: ChatbotStoreProviderProps) => {
  const [isChatOpen, setChatOpen] = useState<boolean>(false);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  const onWidgetEvent = {
    onChatOpen: () => setChatOpen(true),
    onChatClose: () => setChatOpen(false),
  };

  const onSocketEvent = {
    connect: () => {
      console.log('connection established');
    },
    disconnect: () => {
      console.log('connection ended');
    },
  };

  const chatOpenTrigger = (fn: () => void) => {
    chatOpen = fn;
  };

  const sendMessageTrigger = (fn: (string) => void) => {
    sendMessage = fn;
  };

  const sendPayloadTrigger = (fn: (string) => void) => {
    sendPayload = fn;
  };

  const appendChatScript = (scriptToAppend: string) => {
    const script = document.createElement('script');
    script.src = scriptToAppend;
    script.async = true;

    script.onload = async () => {
      await window.WebChat.default(
        {
          initPayload: props.initPayload ?? '/get_started',
          // arbitrary custom data. Stay minimal as this will be added to the socket
          customData: {
            language: 'en',
          },
          params: {
            storage: 'session', // can be set to 'local'  or 'session'. details in storage section.
          },
          title: 'Indemn',
          subtitle: props.subtitle ?? 'Wedding Events Chatbot',
          showSummary: props.showSummary ?? true,
          socketUrl: process.env.REACT_APP_SOCKET_MIDDLEWARE_URL,
          botType: props.botType ?? 'wedding',
          conversationUrl: `${process.env.REACT_APP_CONVERSATION_URL}`,
          conversationAccessToken:
            process.env.REACT_APP_CONVERSATION_ACCESS_TOKEN,
          socketPath: '/socket.io/',
          isSyncEnabled: process.env.REACT_APP_TILEDESK_SYNC_ENABLED
            ? process.env.REACT_APP_TILEDESK_SYNC_ENABLED
            : false,
          opsUrl: process.env.REACT_APP_OPS_API_HOST,
          stripePK:
            'pk_live_51J9htNFp1ZWgizbCML4dwMCkl5zWLUp8SpCfsw8Xnyrnui0fugzWRwjq1ZzuTEL8RxWCXSpwdt5U6kx1xfLNVvGY00UDBU94Iy',
          profileAvatar: true,
          embedded: props.embeded,
          onWidgetEvent,
          onSocketEvent,
          chatOpenTrigger,
          sendMessageTrigger,
          sendPayloadTrigger,
          userTools: props.userTools,
          launcherConfig: props.launcherConfig
        },
        null,
      );
      setScriptLoaded(true);
    };

    document.body.appendChild(script);
  };

  const removeChatScript = (scriptToremove: string) => {
    const allsuspects = document.getElementsByTagName('script');
    for (let i = allsuspects.length; i >= 0; i--) {
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute('src') !== null &&
        allsuspects[i].getAttribute('src').indexOf(`${scriptToremove}`) !== -1
      ) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  };

  useEffect(() => {
    sessionStorage.clear(); // TODO: need a tactic to manage session.
    const src = process.env.REACT_APP_POS_SCRIPT_URL
      ? process.env.REACT_APP_POS_SCRIPT_URL
      : 'https://s3.amazonaws.com/indemn.ai/pos-script/dev/index-dev.js.gz';
    appendChatScript(src);
    return () => {
      removeChatScript(src);
    };
  }, []);

  return (
    <ChatbotContextOld.Provider
      value={{
        chatbotState: {
          isChatOpen,
          scriptLoaded,
        },
      }}
    >
      {props.children}
    </ChatbotContextOld.Provider>
  );
};