import React from 'react';
import Main from 'layouts/main';
import { ChatbotStoreProvider } from 'context/chatbot-state';
import {
  SectionFeatureMedium,
  SectionFeatureLarge,
  SectionHero1,
  BotHostContainer,
} from 'views/components';
import ChatLoader from 'context/chatLoader';
import { generateMetaTags } from 'views/pageHelmet';
import * as constants from 'utils/constants';

const mediumFeatures1 = [
  {
    title: 'Live Intervention',
    content:
      'Maximize the efficiency of your CSR team through our Copilot platform allowing service team to handle **10x more interactions per agent** compared to traditional email and phone',
    media: 'md-live-intervention_bqv95r',
  },
  {
    title: 'AI agents improve over time',
    content:
      'Improve your AI Agents over time with Reinforcement Learning from Human Feedback (RLHF). Provide your AI Agents with the specific knowledge and voice that matches your best CSRs',
    media: 'md-rlhf_e66nrb',
  },
];

const mediumFeatures2 = [
  {
    title: 'Immediate notifications into the right channels',
    content:
      'Alert your service team to clients with high priority requests with notifications tied to email, text or your existing servicing platform',
    media: 'md-live-intervention_bqv95r',
  },
  {
    title: 'AI-powered recommendations',
    content:
      'Save significant time and improve accuracy with AI recommendations informed by your policies, procedures and product information.',
    media: 'md-ai-recommendations_euygim',
  },
];

// const smallFeatures = [
//   {
//     media: 'sm-25-conversion_m52edw',
//     title: 'Record-breaking 25% conversion',
//     content:
//       'We’ve proven that the conversational method for selling insurance obliterates conventional webform conversion',
//   },
//   {
//     media: 'sm-71-users-to-quote_qsiuwk',
//     title: 'Engage to Quote',
//     content:
//       'Leverage our conversational AI technology to convert more policies when agents are interacting with the system',
//   },
//   {
//     media: 'sm-60-cross-sell_liuat8',
//     title: '60% conversion on cross-sell and buy-up',
//     content:
//       'Displaying multiple quote options led to 60% of customers purchasing a more comprehensive package.',
//   },
// ];

const AICsr = (): JSX.Element => {
  return (
    <ChatbotStoreProvider>
      <Main isExtended>
        {generateMetaTags(
          constants.PAGE_CONTENT.SOLUTIONS_CSR.TITLE,
          constants.PAGE_CONTENT.SOLUTIONS_CSR.DESCRIPTION,
          constants.PAGE_CONTENT.SOLUTIONS_CSR.OG_IMAGE,
          window.location.href,
          constants.PAGE_CONTENT.SOLUTIONS_CSR.KEYWORDS,
        )}
        <SectionHero1
          title="The future of service is here"
          content="Implement a 24/7 Multilingual AI CSR with chat, email, text and voice capability that can instantly process repetitive service requests, saving your CSR team significant time, reducing service back log and drastically improving response time."
          message="I’m interested in having an AI CSR for my business"
        />

        <BotHostContainer width={{ xs: 1, xl: 8 / 12 }} />

        <SectionFeatureLarge
          title="Automate frequent policy requests to optimize your service operations"
          content="**50% of service requests from Agents can be handled instantly.** Maximize the efficiency of your CSR team by partnering with AI Agents that can process these frequent service requests instantly, allowing them to handle **10x more interactions** with clients and **redeploy to higher priority tasks**"
          media="lg-service-reduction-01_d04wqo"
        />

        <SectionFeatureMedium features={mediumFeatures1} />

        <SectionFeatureMedium features={mediumFeatures2} />
        <ChatLoader />
      </Main>
    </ChatbotStoreProvider>
  );
};

export default AICsr;
