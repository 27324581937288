import React from 'react';
import { Box } from '@mui/material';
import {
  lazyload,
  AdvancedImage,
  responsive,
  placeholder,
} from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
import { quality, format } from '@cloudinary/url-gen/actions/delivery';

interface Props {
  id: string;
}

const CloudImage = ({ id }: Props): JSX.Element => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'deksgbacz',
    },
  });

  const image = cld
    .image(id)
    .delivery(quality('auto'))
    .delivery(format('auto'));
  return (
    <Box sx={{ '& img': { width: 1 } }}>
      <AdvancedImage
        cldImg={image}
        alt="Cloudinary Images"
        plugins={[lazyload(), responsive(), placeholder()]}
      />
    </Box>
  );
};

export default CloudImage;
