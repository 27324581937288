import React from 'react';
import { CloudImage, Container, GridContainer } from 'components';
import { useTheme, Typography, Box } from '@mui/material';

const partnerLogos = ['GIC_no8cub', 'Insurica_mcxrlt', 'Boundless_Rider_fw3qqm', 'Convergent_rl4sxt', 'Markel_hxwaug', 'Chubb_gl24cp'];

const Partners = (): JSX.Element => {
  const { typography } = useTheme();

  return (
    <GridContainer display='flex' flexDirection='column' width={{ xs: 4 / 4, md: 4 / 8, xl: 12 / 12 }} gap={{ xs: 2, md: 2, xl: 0 }}>
      <Container>
        <Typography color='primary.900' sx={{ ...typography.lg, ...typography.semibold }} textAlign='center'>
          Trusted by leading companies across the value chain
        </Typography>
      </Container>
      <Box display='grid' alignItems='start' gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr', xl: '1fr 1fr 1fr 1fr 1fr 1fr' }} gap={{ xs: 1.5, md: 4, xl: 0 }} width={{ xs: 1 / 2, md: 1 }} margin='auto'>
        {partnerLogos.map(id => (
          <Container
            key={id}
            // sx={{
            //   borderRadius: 3,
            //   border: '1px solid rgba(255, 255, 255, 0.32)',
            //   background: 'rgba(255, 255, 255, 0.12)'
            // }}
          >
            <CloudImage id={id} />
          </Container>
        ))}
      </Box>
    </GridContainer>
  );
};

export default Partners;