import React from 'react';
import { Box } from '@mui/material';
import { AdvancedVideo } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

interface Props {
  id: string;
}

const CloudVideo = ({ id }: Props): JSX.Element => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'deksgbacz',
    },
  });

  return (
    <Box sx={{ '& video': { width: 1 } }}>
      <AdvancedVideo
        cldVid={cld.video(id).quality('auto')}
        autoPlay
        loop
      />
    </Box>
  );
};

export default CloudVideo;
