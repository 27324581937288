import React from 'react';
import { Typography, Box, useTheme, BoxProps } from '@mui/material';
import { FiExternalLink } from 'react-icons/fi'; // Importing from react-icons

interface ExternalLinkProps {
  title?: string;
  href: string;
  ariaLabel: string;
}

const ExternalLink = ({
  title = 'Visit website',
  href,
  ariaLabel,
  ...boxProps
}: ExternalLinkProps & BoxProps): JSX.Element => {
  const { typography, palette } = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      component="a"
      href={href}
      target="_blank"
      aria-label={ariaLabel}
      role="link"
      sx={{
        textDecoration: 'none',
      }}
      {...boxProps}
    >
      <Typography
        color="secondary.600"
        sx={{ ...typography.sm, ...typography.medium }}
      >
        {title}
      </Typography>
      <FiExternalLink size={16} color={palette.secondary[600]} />{' '}
      {/* Replaced FeatherIcon with FiExternalLink */}
    </Box>
  );
};

export default ExternalLink;
