import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: '"Barlow", sans-serif',
  button: {
    textTransform: 'none',
    lineHeight: 1.25,
  },
  h1: {
    lineHeight: 1.25,
  },
  h2: {
    lineHeight: 1.25,
  },
  h3: {
    lineHeight: 1.25,
  },
  h4: {
    lineHeight: 1.25,
  },
  h5: {
    lineHeight: 1.25,
  },
  h6: {
    lineHeight: 1.25,
  },
  body1: {
    lineHeight: 1.25,
  },
  body2: {
    lineHeight: 1.25,
  },
  subtitle1: {
    lineHeight: 1.25,
  },
  subtitle2: {
    lineHeight: 1.25,
  },
  caption: {
    lineHeight: 1.25
  },
  xs: {
    fontSize: 13,
  },
  sm: {
    fontSize: 15,
  },
  base: {
    fontSize: 18,
  },
  lg: {
    fontSize: 21,
  },
  xl: {
    fontSize: 24,
  },
  '2xl': {
    fontSize: 28,
  },
  '3xl': {
    fontSize: 36,
  },
  '4xl': {
    fontSize: 44,
  },
  '5xl': {
    fontSize: 56,
  },
  '6xl': {
    fontSize: 72,
  },
  '7xl': {
    fontSize: 96,
  },
  thin: {
    fontWeight: 100,
  },
  extralight: {
    fontWeight: 200,
  },
  light: {
    fontWeight: 300,
  },
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semibold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
  extrabold: {
    fontWeight: 800,
  },
  black: {
    fontWeight: 900,
  },
};

export default typography;
