import React, { useEffect, useRef } from 'react';

interface ChatLoaderProps {
  scriptUrl?: string;
  elementId?: string;
}

const ChatLoader: React.FC<ChatLoaderProps> = ({
  scriptUrl = 'https://devproxy.indemn.ai/chat-script/65dd96a22a5932c807091596.js', // Default URL
  elementId = 'indemn-webchat',
}) => {
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_CHATBOT_SCRIPT_URL || scriptUrl;
    script.type = 'text/javascript';
    // script.async = true;
    script.defer = true;

    if (chatRef.current) {
      chatRef.current.insertAdjacentElement('afterend', script);
    } else {
      document.body.appendChild(script);
    }

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script); // Clean up the script
      }
    };
  }, [scriptUrl]);

  return <div ref={chatRef} id={elementId} style={{ height: '100%' }} />;
};

export default ChatLoader;
