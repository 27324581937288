import { PaletteMode } from '@mui/material';

export const light = {
  mode: 'light' as PaletteMode,
  primary: {
    main: '#1E3A8A',
    50: '#EFF6FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#93C5FD',
    400: '#60A5FA',
    500: '#3B82F6',
    600: '#2563EB',
    700: '#1D4ED8',
    800: '#1E40AF',
    900: '#1E3A8A',
  },
  secondary: {
    main: '#6366f1',
    50: '#eef2ff',
    100: '#e0e7ff',
    200: '#c7d2fe',
    300: '#a5b4fc',
    400: '#818cf8',
    500: '#6366f1',
    600: '#4f46e5',
    700: '#4338ca',
    800: '#3730a3',
    900: '#1e1b4b',
  },
  tertiary: {
    50: '#ecfeff',
    100: '#cffafe',
    200: '#a5f3fc',
    300: '#67e8f9',
    400: '#22d3ee',
    500: '#06b6d4',
    600: '#0891b2',
    700: '#0e7490',
    800: '#155e75',
    900: '#164e63',
  },
  accent: {
    50: '#fff7ed',
    100: '#ffedd5',
    200: '#fed7aa',
    300: '#fdba74',
    400: '#fb923c',
    500: '#f97316',
    600: '#ea580c',
    700: '#c2410c',
    800: '#9a3412',
    900: '#7c2d12',
  },
  monochrome: {
    slate: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
    gray: {
      50: '#f9fafb',
      100: '#f3f4f6',
      200: '#e5e7eb',
      300: '#d1d5db',
      400: '#9ca3af',
      500: '#6b7280',
      600: '#4b5563',
      700: '#374151',
      800: '#1f2937',
      900: '#111827',
    },
    zinc: {
      50: '#fafafa',
      100: '#f4f4f5',
      200: '#e4e4e7',
      300: '#d4d4d8',
      400: '#a1a1aa',
      500: '#71717a',
      600: '#52525b',
      700: '#3f3f46',
      800: '#27272a',
      900: '#18181b',
    },
    neutral: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#d4d4d4',
      400: '#a3a3a3',
      500: '#737373',
      600: '#525252',
      700: '#404040',
      800: '#262626',
      900: '#171717',
    },
    stone: {
      50: '#fafaf9',
      100: '#f5f5f4',
      200: '#e7e5e4',
      300: '#d6d3d1',
      400: '#a8a29e',
      500: '#78716c',
      600: '#57534e',
      700: '#44403c',
      800: '#292524',
      900: '#1c1917',
    }
  },
  background: {
    bodyPrimary: 'linear-gradient(169deg, #EFF6FF 26.17%, rgba(255, 255, 255, 0.00) 91.62%)',
    directInsuranceHero: 'linear-gradient(180deg, #B5C2ED 0%, #3E62C6 58.33%, #002EA4 100%)',
    directInsuranceList: 'linear-gradient(224deg, rgba(3, 41, 177, 0.60) 2.53%, rgba(0, 71, 88, 0.42) 64.53%), linear-gradient(356deg, #4D0ED2 4.57%, #0074DF 95.97%)',
    navDefault: '#fff',
    navMenu: 'linear-gradient(102deg, #FFF 62.61%, rgba(255, 255, 255, 0.48) 126.96%)',
    header: 'linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, rgba(255, 255, 255, 0.20) 100%)',
    footer: 'conic-gradient(from 36deg at 27.29% 100%, rgba(67, 56, 202, 0.10) 0deg, rgba(37, 99, 235, 0.10) 360deg, rgba(37, 99, 235, 0.10) 360deg), radial-gradient(92.34% 92.26% at 67.14% 0%, rgba(139, 92, 246, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(356deg, #0354CD 4.57%, #000 95.97%)'
  },
};

export const dark = {
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark' as PaletteMode,
  primary: {
    main: '#1976d2',
  },
  secondary: {
    main: '#FFE102',
  },
  background: {
    footer: '#222B45',
  },
};
