import React from 'react';
import { Backdrop, CircularProgress, useTheme } from '@mui/material';

export default function Loading({ loading }: { loading: boolean }) {
  const { palette, zIndex } = useTheme();

  return (
    <Backdrop
      sx={{
        color: palette.primary[900],
        zIndex: zIndex.drawer + 1,
        background: palette.background.bodyPrimary,
      }}
      open={loading}
    >
      <CircularProgress />
    </Backdrop>
  );
}
