import { useEffect, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng, getDetails } from 'use-places-autocomplete';

export const useMap = (query: string) => {
  const [lat, setLat] = useState<number | null>(null);
  const [lng, setLng] = useState<number | null>(null);
  const [img, setImg] = useState<string | null>(null);

  const { suggestions: { data }, setValue } = usePlacesAutocomplete();

  useEffect(() => {
    if (query) {
      setValue(query);
    }
  }, [query]);

  if (data?.length) {
    const { place_id, description } = data[0];

    if (description) {
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setLat(lat);
        setLng(lng);
      });
    }

    if (place_id) {
      // get image
      getDetails({ placeId: place_id })
        .then((res: any) => {
          if (res.photos?.length) {
            setImg(res.photos[0].getUrl());
          }
        });
    }
  }

  return { lat, lng, img };
};