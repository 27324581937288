import React, { useState } from 'react';
import { Button, useTheme, Box, BoxProps } from '@mui/material';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'; // Importing icons from react-icons

interface Props {
  children?: React.ReactNode;
  label: string;
}

const NavCollapse = ({
  label,
  children,
  ...boxProps
}: Props & BoxProps): JSX.Element => {
  const { typography } = useTheme();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        variant="text"
        endIcon={open ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
        size="medium"
        onClick={() => setOpen((prev) => !prev)}
        sx={{ ...typography.sm }}
      >
        {label}
      </Button>
      {open && (
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          alignItems="baseline"
          pl={4}
          {...boxProps}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default NavCollapse;
