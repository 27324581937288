import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Main from 'layouts/main';
import { Box, useTheme } from '@mui/material';
import { FiMessageCircle } from 'react-icons/fi'; // Importing from react-icons
import { ChatbotStoreProviderOld } from 'context/chatbot-state-old';
import { Image, GridContainer, MarkDown, Loading } from 'components';
import { BotHostContainerOld, AskIndmenButtonOld, Faq } from 'views/components';
import { useFAQ } from 'hooks/use-faq';
import NotFound from 'views/not-found';
import { parseAdditionalSlots } from 'utils/helper-generic';

export default function GetInsurance(): JSX.Element {
  const { slug } = useParams();
  const { typography, palette } = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [event, setEvent] = useState<Event | null>(null);
  const { faqs } = useFAQ(event?.faq_table, event?.faq_list);

  useEffect(() => {
    const fetchProductBySlug = async () => {
      setLoading(true);
      try {
        const res = await (
          await fetch(
            `${process.env.REACT_APP_OPS_API_HOST}/fetch-product-by-slug`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ slug }),
            },
          )
        ).json();

        setEvent(res.product);

        // update page title
        document.title = res.product.page_title;
        // update page description
        const metaDescription = document.querySelector(
          'meta[name="description"]',
        );
        if (metaDescription) {
          metaDescription.setAttribute(
            'content',
            res.product.page_meta_description,
          );
        }
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    fetchProductBySlug();
  }, []);

  if (loading) {
    return <Loading loading={!event} />;
  }

  if (!event) {
    return <NotFound />;
  }

  const payload = `/get_started${JSON.stringify({
    first_message: event.first_message,
    package_config: event.package_config,
    mandate_liability: event.mandate_liability,
    mandate_cancellation: event.mandate_cancellation,
    path_type: 'product',
    product: event.slug,
    cancellation_available: true,
    ...parseAdditionalSlots(event.additional_slots),
  })}`;

  return (
    <ChatbotStoreProviderOld
      botType={
        process.env.REACT_APP_BOT_TYPE
          ? process.env.REACT_APP_BOT_TYPE
          : event.bot
      }
      launcherConfig={{ useLauncher: true }}
      initPayload={payload}
    >
      <Main>
        <Image
          src={event.hero_img}
          alt={event.slug}
          sx={{
            display: 'flex',
            background: palette.background.directInsuranceHero,
            clipPath: 'url(#myClip)',
            height: { xs: '70vh', md: '50vh', xl: 'unset' },
          }}
          imageProps={{
            style: {
              objectFit: 'cover',
              mixBlendMode: 'overlay',
            },
          }}
        />

        <svg width="0" height="0">
          <defs>
            <clipPath id="myClip" clipPathUnits="objectBoundingBox">
              <path
                transform="scale(0.00069444444, 0.00202839756)"
                d="M1440 0H0V438.385C493.657 513.754 891.5 507 1440 438.385V0Z"
              ></path>
            </clipPath>
          </defs>
        </svg>

        <Box
          sx={{
            background:
              'radial-gradient(68.79% 89.54% at 114.1% -18.98%, rgba(0, 99, 247, 0.20) 0%, rgba(85, 133, 255, 0.20) 50.52%, rgba(245, 249, 255, 0.20) 100%), linear-gradient(326deg, rgba(255, 255, 255, 0.88) 48.8%, rgba(220, 219, 254, 0.88) 99.98%)',
            mt: { xs: '-30vh', md: '-12vh' },
          }}
        >
          <GridContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                borderRadius: '12px',
                border: '1px solid rgba(255, 255, 255, 0.32)',
                background:
                  'linear-gradient(155deg, rgba(255, 255, 255, 0.94) 36.16%, rgba(255, 255, 255, 0.20) 85.22%)',
                backdropFilter: 'blur(32px)',
                width: { xs: 1, md: 6 / 8, xl: 6 / 12 },
                margin: '0 auto',
                padding: { xs: '36px 16px', md: '36px 24px' },
              }}
            >
              <MarkDown
                sx={{ ...typography['3xl'], ...typography.bold, mb: 2 }}
                text={event.hero_headline}
              />

              <MarkDown
                sx={{ ...typography.base, ...typography.regular, mb: 4.5 }}
                text={event.hero_body}
              />

              <AskIndmenButtonOld
                variant="contained"
                endIcon={<FiMessageCircle />}
                size="large"
              >
                {event.hero_cta}
              </AskIndmenButtonOld>
            </Box>
          </GridContainer>
          <BotHostContainerOld />
          <Faq faqs={faqs} />
          {/* <Pricing /> */}
        </Box>
      </Main>
    </ChatbotStoreProviderOld>
  );
}
