import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props {
  children?: React.ReactNode;
}

const Square = ({ children, ...boxProps }: Props & BoxProps): JSX.Element => {
  return (
    <Box
      position="relative"
      display="inline-block"
      width={1}
      {...boxProps}
    >
      <Box marginTop="100%" />
      <Box
        position="absolute"
        top={0}
        left={0}
        width={1}
        height={1}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Square;
